import { DatePicker, Form, Input, InputNumber } from 'antd';
import React, { useMemo } from 'react';
import { CustomerMeter } from '../../../models';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	inputType: 'number' | 'text' | 'date';
	record: CustomerMeter;
	index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
	const inputNode = useMemo(() => {
		switch (inputType) {
			case 'number':
				return <InputNumber />;
			case 'date':
				return <DatePicker
					format="DD/MM/YYYY"
					style={{ width: '100%' }}
					// maxDate={dayjs()}
					value={record.installationDate ? (record.installationDate) : undefined}
				/>
			case 'text':
				return <Input />;
			default:
				return <></>;
		}
	}, [])

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `${title} requis !`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default EditableCell;