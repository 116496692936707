import { CustomerMeter } from '../models';
import { FetchResponse } from '../models/fetch-response-model';
import { http } from './axios';

export class CustomerMeterService {
	public static GetCustomerMeter(customerId: number, siteId: number): Promise<FetchResponse> {
		return http.get(`/customer-meter/${customerId}/site/${siteId}`);
	}

	public static Save(payload: CustomerMeter): Promise<FetchResponse> {
		return http.put(`/customer-meter/save`, payload);
	}
}
