import React, { useEffect, useState } from "react";
import {
  Modal,
  notification,
  Row,
  Col,
  Button,
  List,
  Checkbox,
  Space,
  Popover,
} from "antd";
import { ReportService } from "../../../services";
import { InfoCircleOutlined } from "@ant-design/icons";
import { numberAsCurrency } from "../../../utils/string";

interface GenerateInvoicesProps {
  open: boolean;
  onOk: (params?: any) => void;
  onCancel: () => void;
  data: GenerateInvoicesPayload[] | null;
  siteId?: number;
}

export interface GenerateInvoicesPayload {
  reportDate: any;
  customer: string;
  meter: string;
  district: string;
  reportId: number;
  currentIndex: number;
  lastBilledIndex: number;
  customerId: number;
  customerMeterId: number;
  districtId: number;
  volumeConsumed: number;
  variables: { value: number; label: string }[];
  totalToPay: number;
  amountConsumed: number;
  image: string;
  invoiceId: number;
  checked?: boolean;
  volumeConsumedError: string;
  haveOldReportWithNoInvoice: boolean;
  disable: boolean;
  errors: string[];
}

export const GenerateInvoices: React.FC<GenerateInvoicesProps> = ({
  open,
  onOk,
  onCancel,
  data = null,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);
  const [_data, setData] = useState<GenerateInvoicesPayload[]>([]);

  useEffect(() => {
    setData(data || []);
    return () => {};
  }, [data]);


  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await ReportService.updateReports({
        siteId,
        reports: _data.filter((row) => row.checked),
      });
      onOk(response);
      notification.success({ message: `Facture(s) generée(s)` });
    } catch (error: any) {
      notification.error({ message: error?.message || "Erreur inconnue!" });
    } finally {
      setLoading(false);
    }
  };

  const _dataChecked = _data.filter((d) => d?.checked).length > 0;

  if (_data === null) return null;

  return (
    <Modal
      title="Génération facture"
      centered
      open={open}
      styles={{ header: { textAlign: "center" } }}
      onCancel={onCancel}
      width={window.innerWidth * 0.8}
      okText={"GENERER LES FACTURES"}
      cancelText={"ANNULER"}
      confirmLoading={loading}
      footer={[
        <Button disabled={loading} key="1" type="default" onClick={onCancel}>
          ANNULER
        </Button>,
        <Button
          key="3"
          type="primary"
          loading={loading}
          disabled={!_dataChecked || loading}
          onClick={onSubmit}
        >
          GENERER LES FACTURES
        </Button>,
      ]}
    >
      <List
        dataSource={_data || []}
        renderItem={(item) => (
          <List.Item
            key={item.reportId}
            style={{ opacity: item.disable ? 0.7 : 1,width:'100%',}}
          >
            <Row style={{width:'100%'}}>
              <Col span={3}>
                <div>
                  {" "}
                  <Space>
                    <Checkbox
                      value={item?.checked}
                      disabled={item.disable}
                      onChange={(e) => {
                        item.checked = e.target.checked;
                        setData(() => [..._data]);
                      }}
                    />
                    {item.disable && item.errors.length > 0 ? (
                      <Popover
                        placement="left"
                        content={() => {
                          return item.errors.map((error) => <p>{error}</p>);
                        }}
                      >
                        <InfoCircleOutlined style={{ color: "orange" }} />
                      </Popover>
                    ) : null}
                  </Space>
                </div>
              </Col>
              <Col span={9}>
                <div>
                  <b>
                    {item.reportDate} - {item.customer} ({item.meter})
                  </b>
                </div>
              </Col>
              <Col span={5}>
                <div>
                  Vol. cons.:{" "}
                  <span style={{ color: item.currentIndex < item.lastBilledIndex ? "red" : "green",}}>
                    {item.volumeConsumed}
                  </span>
                </div>
              </Col>

              <Col span={7} style={{textAlign:'end'}}>
                {!item.disable ? (
                  <div>
                    Total à payer:{" "}
                    <span style={{ color: "green" }}>{numberAsCurrency(item.totalToPay)} Ar</span>
                  </div>
                ) : (
                  <div />
                )}
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Modal>
  );
};
