import React, { useEffect, useState } from "react";
import { Space, Spin, Typography } from "antd";
import { ReportDetails, Invoice } from "../../../models";
import { InvoiceService } from "../../../services";
import { numberAsCurrency } from "../../../utils/string";

interface ReportInvoiceDetailsProps {
  report: ReportDetails;
}

export default function ReportInvoiceDetails({ report }: ReportInvoiceDetailsProps) {
  const [invoice, setInvoice] = useState<Invoice>();

  useEffect(() => {
    if (report) {
      InvoiceService.getInvoiceDetailsByReportAndInvoiceId(report.id,report.customer_invoice_id)
        .then((result) => {
          setInvoice(result);
        })
        .catch((e) => {});
    }
    return () => {};
  }, [report]);

  if (!invoice) return <Spin />;

  return (
    <Space direction="vertical" size={"large"} style={{width:'350px'}}>
      <Typography.Text>Date facturation:   {invoice.date}</Typography.Text>
      <Typography.Text>Ref. facture:  {invoice.ref}</Typography.Text>
      <Typography.Text>Client facturation:  {invoice.customer}</Typography.Text>
      <Typography.Text>Compteur:  {invoice.meter}</Typography.Text>
      <Space direction="horizontal" style={{justifyContent:'space-between',display:'flex'}}>
        <Typography.Text>Volume facturé:   <span style={{color:'green'}}>{invoice.volume}</span></Typography.Text>
        <Typography.Text>A payer:   <span style={{color:'green'}}>{numberAsCurrency(invoice.total)} Ar</span></Typography.Text>
      </Space>
      <Typography.Text>Par:  {invoice.user}</Typography.Text>
    </Space>
  );
}
