import { FC, useCallback, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { Col, Row, Table, TableColumnsType, DatePicker, Form, Select, Button, Space, FormProps, TimeRangePickerProps, Popover, notification, message, Card, Input } from "antd";
import { ReportPerDistrict, FetchResponse, ReportPerUser, ReportDetails } from "../../../models";
import { ReportService } from "../../../services";
import moment from "moment";
import { DollarCircleFilled, DollarCircleOutlined, EditOutlined, FileOutlined, FilePdfOutlined, FilterOutlined, UnorderedListOutlined } from "@ant-design/icons";
import ReportDetailsPopover from "./popover-details";
import { ReportForm, ReportFormPayload } from "../ReportForm";
import { useStore } from "../../../store";
import ReportInvoiceDetails from "./report-invoice.details";
import { GenerateInvoices } from "../GenerateInvoices";
import { dateFormat, generateFileURL } from "../../../utils/string";
import { useColumnFilter } from "../../../hooks/useColumnFilter";
import { openFile } from "../../../utils/open-file";
import { InnerCard } from "../../../components";

const { RangePicker } = DatePicker;

enum Mode {
	DETAILS = 1,
	PERUSER = 2,
	PERDISTRICT = 3,
}

const defaultDate: number = -365;

const ReportPage: FC = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [reportPerDistrict, setReportPerDistrict] = useState<ReportPerDistrict[]>([]);
	const [reportPerUser, setReportPerUser] = useState<ReportPerUser[]>([]);
	const [reportDetails, setReportDetails] = useState<ReportDetails[]>([]);
	const [reportDetailsFiltered, setReportDetailsFiltered] = useState<ReportDetails[]>([]);
	const [mode, setMode] = useState<Mode>(Mode.DETAILS);
	const [pageSize, setPageSize] = useState<number>(15);
	const [filter, setFilter] = useState<FilterType>(()=>{
		const searchParams = new URLSearchParams(window.location.search);
		const periode:any[] = [null, null];
		const fromDate = searchParams.get('fromDate');
		const toDate = searchParams.get('toDate');

		periode[0] = fromDate ? (dayjs(fromDate)) : dayjs().add(defaultDate, 'd');
		periode[1] = toDate ? (dayjs(toDate)) : dayjs();

		return Object.assign(new FilterType(), { periode })
	});
	const [openReportForm, setOpenReportForm] = useState(false);
	const [openGenerateInvoices, setOpenGenerateInvoices] = useState(false);
	const { state: { user, currentSite } } = useStore();
	const [report, setReport] = useState<ReportFormPayload | null>(null);
	const [reports, setReports] = useState<any[]>([]);
	const [messageApi, contextHolder] = message.useMessage();

	const { getColumnSearchProps } = useColumnFilter<ReportDetails>();

	const getRangeDate = useCallback(() => {
		let fromDate: string = '';
		let toDate: string = '';

		if (filter.periode[0]) {
			fromDate = moment(filter.periode[0].$d).format('YYYY-MM-DD');
		}

		if (filter.periode[1]) {
			toDate = moment(filter.periode[1].$d).format('YYYY-MM-DD');
		}

		return [fromDate, toDate];
	}, [filter.periode]);

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);
				let [fromDate, toDate]: string[] = getRangeDate();
				if (currentSite.id !== 0) {
					let result: FetchResponse;
					if (filter.categorie === "3") { // per district
						setMode(Mode.PERDISTRICT);
						result = await ReportService.getReportPerDistrict(fromDate, toDate, currentSite.id);
						if (result?.status === "success") {
							setReportPerDistrict(result.data);
						}
					} else if (filter.categorie === "2") { // per reporter
						setMode(Mode.PERUSER);
						result = await ReportService.getReportPerUser(fromDate, toDate, currentSite.id);
						if (result?.status === "success") {
							setReportPerUser(result.data);
						}
					} else if (filter.categorie === "1") { // details
						setMode(Mode.DETAILS);
						result = await ReportService.getReportDetails(fromDate, toDate, currentSite.id);
						if (result?.status === "success") {
							setReportDetails(result.data);
							setReportDetailsFiltered(result.data);
						}
					}
				} else {
					// messageApi.open({
					// 	type: 'error',
					// 	content: 'Erreur de la recuperation du site',
					// });
				}
			} catch (e: any) {
				console.log("Fetch data: ", e.message);
			} finally {
				setLoading(false);
			}
		};
		fetch();

	}, [currentSite.id, messageApi, getRangeDate, filter.categorie]);

	const onValuesChange: FormProps<FilterType>["onValuesChange"] = async (_, filter) => {
		try {
			setFilter(filter);
		} catch (e) {
			console.info("Report page error: ", e);
		} finally {
			setLoading(false);
		}
	};

	const handleChangePageSize = (current: number, newPageSize: number) => {
		setPageSize(newPageSize);
	};

	// Pagination configuration
	const pagination = {
		pageSize,
		showSizeChanger: true,
		onShowSizeChange: handleChangePageSize,
		// Other pagination configurations
	};

	const detailsColumns: TableColumnsType<ReportDetails> = [
		{
			title: 'Date',
			dataIndex: 'reportDate',
			filterSearch: true,
			defaultSortOrder: "descend",
			align: 'center',
			sorter: (a, b) => a.reportDate.localeCompare(b.reportDate),
			...getColumnSearchProps('reportDate', 'date'),
			render: (text) => moment(text).format(dateFormat),
		},
		{
			title: 'Client',
			dataIndex: 'firstName',
			sorter: (a, b) => a.firstName.localeCompare(b.firstName),
			...getColumnSearchProps('firstName', "search", [], undefined, (_text, record) => getFullName(record), getFullName)
		},
		{
			title: 'Quartier',
			dataIndex: 'district_label',
			sorter: (a, b) => a.district_label.localeCompare(b.district_label),
			...getColumnSearchProps('district_label', 'select', reportDetailsFiltered)
		},
		{
			title: 'Releveur',
			dataIndex: 'user_lastname',
			sorter: (a, b) => a.user_lastname.localeCompare(b.user_lastname),
			...getColumnSearchProps('user_lastname'),
			render: (text, record) => `${record.user_lastname || ""} ${record.user_firstname || ""}`,
		},
		{
			title: 'Dernier index',
			dataIndex: 'currentIndex',
			align: 'right',
			render: (value) => value?.toLocaleString(),
			sorter: (a, b) => a.currentIndex - b.currentIndex,
		},
		{
			title: <div style={{ textAlign: 'center' }}>Actions</div>,
			align: 'right',
			dataIndex: '',
			key: 'x',
			render: (text, record) => (
				<Space>
					{record.customer_invoice_id ? (
						<Popover
							title={<Row justify={"center"}>Facture</Row>}
							content={<ReportInvoiceDetails report={record} />}
						>
							<DollarCircleFilled />
						</Popover>
					) : <DollarCircleOutlined />}
					{record.customer_invoice_pdf ? (
						<FilePdfOutlined onClick={openFile(record.customer_invoice_pdf)} />
					) : null}
					<Popover
						placement="left"
						title={<Row justify={"center"}>Details relevé</Row>}
						content={<ReportDetailsPopover report={record} />}
					>
						<a href="#none"><FileOutlined /></a>
					</Popover>
					<EditOutlined style={{ color: 'green' }} onClick={() => onEditReport(record)} />
				</Space>
			),
		}
	];

	const onEditReport = (payload: ReportDetails) => {
		if (user) {
			ReportService.getReportById(payload.id, user?.site.id).then(data => {
				setReport({
					id: payload.id,
					reportDate: dayjs(payload.reportDate),
					customers: [{ value: payload.customer_id, label: `${payload.firstName || ''} ${payload.lastName || ''}` }],
					meters: [{ value: payload.customerMeterId, label: payload.number }],
					districts: [{ value: payload.district_id, label: payload.district_label }],
					reportId: payload.id,
					siteId: user?.site.id,
					currentIndex: payload.currentIndex,
					lastBilledIndex: data.lastBilledIndex,
					customerId: payload.customer_id,
					customerMeterId: payload.customerMeterId,
					districtId: payload.district_id,
					volumeConsumed: data.volumeConsumed,
					variables: data.variables,
					totalToPay: data.totalToPay,
					amountConsumed: data.amountConsumed,
					image: generateFileURL(payload.image || 'images/meters/default.jpg'),
					invoiceId: payload.customer_invoice_id
				})
				setOpenReportForm(true)
			}).catch(error => {
				notification.error({ message: error?.message || "Erreur inconnue!", });
			});
		}
	}

	const onGenerateInvoices = () => {
		if (user) {
			ReportService.getReportsBySiteId(user?.site.id).then(data => {
				setReports(data)
				setOpenGenerateInvoices(true)
			}).catch(error => {
				notification.error({ message: error?.message || "Erreur inconnue!", });
			});
		}
	}

	const onOk = (result: any) => {
		const tmp = [...reportDetailsFiltered];
		const index = tmp.findIndex(report => report.id === result.reportId);
		tmp[index].currentIndex = result.currentIndex;
		tmp[index].customer_invoice_id = result.invoiceId;
		tmp[index].customer_invoice_pdf = result.pdf;
		tmp[index].reportDate = result.reportDate;

		setReportDetails(tmp);
		setReportDetailsFiltered(tmp);
		setOpenReportForm(false);
	};


	const onOkGenerateInvoice = (results: any) => {
		const tmp = [...reportDetailsFiltered];

		results.forEach((result: any) => {
			const index = tmp.findIndex(report => report.id === result.reportId);
			tmp[index].currentIndex = result.currentIndex;
			tmp[index].customer_invoice_id = result.invoiceId;
			tmp[index].customer_invoice_pdf = result.pdf;
			tmp[index].reportDate = result.reportDate;
		})

		setReportDetails(tmp);
		setReportDetailsFiltered(tmp);
		setOpenGenerateInvoices(false);
	};

	const search = (e: any) => {
		const value = e.target.value;
		const regExp = new RegExp(value, 'gi');
		const filterTable = reportDetails.filter((o: any) => Object.keys(o).some(k => regExp.test(o[k])));
		setReportDetailsFiltered(filterTable);
	};

	return (
		<Space direction="vertical" size={"small"} style={{ display: 'flex' }}>
			{contextHolder}
			<InnerCard
				title={"Filtres"}
				icon={<FilterOutlined />}
			>
				<Form
					name="report_filter"
					onValuesChange={onValuesChange}
					style={{ maxWidth: 'none' }}
				>
					<Row gutter={24} justify={"start"}>
						<Col span={8}>
							<Form.Item
								name={"periode"}
								initialValue={filter.periode}
								style={{ margin: 0 }}
							>
								<RangePicker
									presets={rangePresets}
									placeholder={["01-01-1970", "Aujourd'hui"]}
									needConfirm={true}
									allowEmpty={[false, true]}
									name="periode"
									format={dateFormat}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name={"categorie"}
								initialValue={Mode.DETAILS.toString()}
								style={{ margin: 0 }}
							>
								<Select
									showSearch
									style={{ width: '100%' }}
									optionFilterProp="children"
									filterOption={(input, option) => (option?.label ?? '').includes(input)}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={categories}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</InnerCard>
			<InnerCard
				title={"Relevés"}
				icon={<UnorderedListOutlined />}
			>
				<Row justify={"space-between"} align={"middle"} style={{ marginBottom: 10 }}>
					{filter.categorie === Mode.DETAILS.toString() &&
						<Col>
							<Input.Search
								placeholder="Rechercher..."
								enterButton
								onChange={search}
							/>
						</Col>
					}
					<Col style={{ display: 'flex' }}>
						{filter.categorie === Mode.DETAILS.toString() &&
							<div
								style={{
									paddingTop: 6,
									marginRight: '20px'
								}}
							>
								Total: <strong>{reportDetailsFiltered.length}</strong>
							</div>
						}
						<Button type="primary" className="bg-blue" onClick={onGenerateInvoices}>GENERER FACTURE</Button>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={24}>
						{mode === Mode.PERDISTRICT && <Table
							columns={perDistrictColumns}
							rowKey={"district_id"}
							dataSource={reportPerDistrict}
							size="small"
							loading={loading}
							pagination={pagination}
							scroll={{ y: 585 }}
						/>}
						{mode === Mode.PERUSER && <Table
							columns={perUserColumns}
							rowKey={"id"}
							dataSource={reportPerUser}
							size="small"
							loading={loading}
							pagination={pagination}
							scroll={{ y: 585 }}
						/>}
						{mode === Mode.DETAILS && <Table
							columns={detailsColumns}
							rowKey={"id"}
							dataSource={reportDetailsFiltered}
							size="small"
							loading={loading}
							pagination={pagination}
							scroll={{ y: 585 }}
						/>}
					</Col>
				</Row>
			</InnerCard>
			{openReportForm ?
				<ReportForm
					onOk={onOk}
					onCancel={() => {
						setOpenReportForm(false)
					}}
					data={report}
					open={openReportForm}
				/> : null}

			{openGenerateInvoices ?
				<GenerateInvoices
					onOk={onOkGenerateInvoice}
					siteId={user?.site.id}
					onCancel={() => {
						setOpenGenerateInvoices(false)
					}}
					data={reports}
					open={openGenerateInvoices}
				/> : null
			}
		</Space>
	)
};

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: 'Mois actuel', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
	{ label: 'Dernier 7 jours', value: [dayjs().add(-7, 'd'), dayjs()] },
	{ label: 'Dernier 14 jours', value: [dayjs().add(-14, 'd'), dayjs()] },
	{ label: 'Dernier 30 jours', value: [dayjs().add(-30, 'd'), dayjs()] },
	{ label: 'Dernier 90 jours', value: [dayjs().add(-90, 'd'), dayjs()] },
];

const categories = [
	{
		value: '3',
		label: 'Par quartier',
	},
	{
		value: '2',
		label: 'Par releveur',
	},
	{
		value: '1',
		label: 'Détails',
	},
];

const perDistrictColumns: TableColumnsType<ReportPerDistrict> = [
	{
		title: 'Quartier',
		dataIndex: 'district_label',
		filterSearch: true,
		defaultSortOrder: "ascend",
		sorter: (a, b) => a.district_label.localeCompare(b.district_label),
	},
	{
		title: 'Nb relevés effectuées',
		dataIndex: 'total_report',
		align: 'right',
		render: (value) => value?.toLocaleString(),
		sorter: (a, b) => a.total_report - b.total_report,
	},
	{
		title: 'Nb total de compteurs',
		dataIndex: 'total_meter',
		align: 'right',
		render: (value) => value?.toLocaleString(),
		sorter: (a, b) => a.total_meter - b.total_meter,
	},
];

const perUserColumns: TableColumnsType<ReportPerUser> = [
	{
		title: 'Releveur',
		dataIndex: 'lastName',
		filterSearch: true,
		defaultSortOrder: "ascend",
		sorter: (a, b) => a.lastName.localeCompare(b.lastName),
		render: (text, record) => `${record.lastName || ""} ${record.firstName || ""}`,
	},
	{
		title: 'Nb relevés effectuées',
		dataIndex: 'total_report',
		align: 'right',
		render: (value) => value?.toLocaleString(),
		sorter: (a, b) => a.total_report - b.total_report,
	}
];

class FilterType {
	periode: any[] = [];
	categorie: string = Mode.DETAILS.toString();
	search: string = ''
}

export const getFullName = (record: any) => `${record.lastName || ""} ${record.firstName || ""}`;

export default ReportPage;