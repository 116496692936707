import { DashboardOutlined } from '@ant-design/icons';
import type { TableProps } from 'antd';
import { Button, Form, message, Popconfirm, Table, Typography } from 'antd';
import dayjs from "dayjs";
import React, { useCallback, useState } from 'react';
import { InnerCard } from '../../../components';
import { CustomerMeter } from '../../../models';
import { CustomerMeterService } from '../../../services';
import { dateFormat } from '../../../utils';
import EditableCell from './editable-cell';

interface ITypeProps {
	originData: CustomerMeter[];
	loading: boolean;
	customerId: number;
	refreshData: () => void;
}

const CustomerMeterComponent: React.FC<ITypeProps> = ({ originData, loading, customerId, refreshData }) => {
	const [form] = Form.useForm();
	const [data, setData] = useState<CustomerMeter[]>(originData);
	const [editingKey, setEditingKey] = useState<number>(0);

	const isEditing = (record: CustomerMeter) => record.id === editingKey;

	const handleAdd = useCallback(() => {
		const newData: Partial<CustomerMeter> = {
			id: 0,
			installationDate: dayjs().toDate(),
			lastIndex: 0,
			meterNumber: "",
			meterId: 0,
			customerId: customerId,
		};
		setData([newData as CustomerMeter, ...data]);
		setEditingKey(0);
	}, [data]);

	const edit = (record: CustomerMeter) => {
		form.setFieldsValue({ name: '', age: '', address: '', ...record });
		setEditingKey(record.id!);
	};

	const cancel = () => {
		setEditingKey(0);
	};

	const save = async (key: React.Key) => {
		try {
			const row = (await form.validateFields()) as CustomerMeter;

			const newData = [...data];
			const index = newData.findIndex((item) => key === item.id);
			if (index > -1) {
				const item = newData[index];
				const updatedData: CustomerMeter = {
					...item,
					...row,
				};
				const res = await CustomerMeterService.Save(updatedData);
				if (res.status == "success") {
					newData.splice(index, 1, updatedData);
					setData(newData);
					setEditingKey(0);
					refreshData();
					message.success(res.message);
				} else {
					message.error(res.message);
				}
			} else {
				newData.push(row);
				setData(newData);
				setEditingKey(0);
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const columns = [
		{
			title: 'Installation',
			dataIndex: 'installationDate',
			width: '25%',
			inputType: 'date',
			editable: true,
			render: (text: any) => {
				if (text === null || text === undefined) return "";
				return dayjs(text).format(dateFormat)
			},
		},
		{
			title: 'Numéro compteur',
			dataIndex: 'meterNumber',
			width: '15%',
			inputType: 'text',
			editable: true,
		},
		{
			title: 'Index initial',
			dataIndex: 'lastIndex',
			width: '40%',
			inputType: 'number',
			editable: true,
		},
		{
			title: 'Operation',
			dataIndex: 'operation',
			render: (_: any, record: CustomerMeter) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link onClick={() => save(record.id)} style={{ marginInlineEnd: 8 }}>
							Enregistrer
						</Typography.Link>
						<Popconfirm title="Ignorer les modifications ?" onConfirm={cancel}>
							<a>Annuler</a>
						</Popconfirm>
					</span>
				) : (
					<Typography.Link disabled={editingKey !== 0} onClick={() => edit(record)}>
						Modifier
					</Typography.Link>
				);
			},
		},
	];

	const mergedColumns: TableProps<CustomerMeter>['columns'] = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: CustomerMeter) => ({
				record,
				inputType: col.inputType,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	return (
		<InnerCard
			title={`Compteurs`}
			icon={<DashboardOutlined />}
			extra={
				<div className="customer-db" onClick={handleAdd}>
					<Button type="primary" className="bg-blue" block>Nouveau compteur</Button>
				</div>
			}
		>
			<Form form={form} component={false}>
				<Table<CustomerMeter>
					components={{
						body: { cell: EditableCell },
					}}
					dataSource={data}
					rowKey={"id"}
					columns={mergedColumns}
					pagination={{ onChange: cancel }}
					size="small"
					loading={loading}
				/>
			</Form>
		</InnerCard>
	);
};

export default CustomerMeterComponent;