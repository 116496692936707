import { Site } from '../models';
import { http } from './axios';

export class SettingService {
	public static settings(data:FormData):Promise<{emails:string[],site:Site}>{
		return http.post(`/settings`, data);
	}

	public static getSettings(siteId:number):Promise<{emails:string[],site:Site}>{
		return http.get(`/settings/${siteId}`);
	}
}
