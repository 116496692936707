import { FC } from "react";
import { Col, Row, Typography, Image } from "antd";
import { CalendarOutlined, DashboardOutlined, FieldNumberOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { ReportDetails } from "../../../models";
import { generateFileURL } from "../../../utils/string";
const { Text } = Typography;


interface IDetailReleveProps {
	report: ReportDetails
}

const style = {
	width: "350px"
};

const fontStyle: React.CSSProperties = {
	fontSize: "20px",
};

const rowStyle: React.CSSProperties = {
	marginTop: "20px",
	marginBottom: "20px",
};

const ReportDetailsPopover: FC<IDetailReleveProps> = ({ report }) => {
	return (
		<div style={style}>
			<Row justify="start" gutter={[16, 16]} style={rowStyle}>
				<Col span={2} offset={4}>
					<Text style={fontStyle}>
						<HomeOutlined />
					</Text>
				</Col>
				<Col span={16} offset={2}>
					<Text style={fontStyle} type="secondary">
						{report.site_label}
					</Text>
				</Col>
			</Row>
			<Row justify="start" gutter={[16, 16]} style={rowStyle}>
				<Col span={2} offset={4}>
					<Text style={fontStyle}>
						<CalendarOutlined />
					</Text>
				</Col>
				<Col span={16} offset={2}>
					<Text style={fontStyle} type="secondary">
						{moment(report.reportDate).format("DD/MM/YYYY")}
					</Text>
				</Col>
			</Row>
			<Row justify="start" gutter={[16, 16]} style={rowStyle}>
				<Col span={2} offset={4}>
					<Text style={fontStyle}>
						<UserOutlined />
					</Text>
				</Col>
				<Col span={16} offset={2}>
					<Text style={fontStyle} type="secondary">
						{`${report.lastName || ""} ${report.firstName || ""}`}
					</Text>
				</Col>
			</Row>
			<Row justify="start" gutter={[16, 16]} style={rowStyle}>
				<Col span={2} offset={4}>
					<Text style={fontStyle}>
						<FieldNumberOutlined />
					</Text>
				</Col>
				<Col span={16} offset={2}>
					<Text style={fontStyle} type="secondary">
						{report.number}
					</Text>
				</Col>
			</Row>
			<Row justify="start" gutter={[16, 16]} style={rowStyle}>
				<Col span={2} offset={4}>
					<Text style={fontStyle}>
						<DashboardOutlined />
					</Text>
				</Col>
				<Col span={16} offset={2}>
					<Text style={fontStyle} type="secondary">
						{report.currentIndex}
					</Text>
				</Col>
			</Row>
			<Row justify={"center"}>
				<Image
					width={200}
					src={generateFileURL(report.image || 'images/meters/default.jpg')}
				/>
			</Row>
		</div>
	)
};

export default ReportDetailsPopover;