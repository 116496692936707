import { CreditCardOutlined, DashboardOutlined, ExclamationCircleFilled, RollbackOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Flex, Form, GetProp, Input, message, Modal, notification, Radio, Row, Select, Space, Spin, Table, TableColumnsType, Upload, UploadFile, UploadProps } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { InnerCard } from "../../../components";
import { useColumnFilter } from "../../../hooks/useColumnFilter";
import { CustomerDetails, CustomerMeter, FetchResponse, MeterDetails } from "../../../models";
import { CustomerMeterService, CustomerService, MeterService, ProspectService } from "../../../services";
import { useStore } from "../../../store";
import { dateFormat } from "../../../utils";
import { getFullName } from "../report";
import CustomerMeterComponent from "./customer-meter";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

enum ScreenMode {
	CUSTOMER,
	METER,
	FICHE,
}

const formItemLayout = {
	wrapperCol: {
		xs: { span: 9 },
		sm: { span: 9 },
	},
	labelCol: {
		xs: { span: 3 },
		sm: { span: 3 },
	},
};

const rowItemLayout = {
	wrapperCol: {
		xs: { span: 18 },
		sm: { span: 18 },
	},
	labelCol: {
		xs: { span: 6 },
		sm: { span: 6 },
	},
};

const CustomerPage: FC = () => {
	const [customerForm] = Form.useForm();
	const [customerDetails, setCustomerDetails] = useState<CustomerDetails[]>([]);
	const [customerDetailsTMP, setCustomerDetailsTMP] = useState<CustomerDetails[]>([]);
	const [meterDetails, setMeterDetails] = useState<MeterDetails[]>([]);
	const [meterDetailsTMP, setMeterDetailsTMP] = useState<MeterDetails[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(15);
	const [currentFile, setCurrentFile] = useState<UploadFile[]>([]);
	const [screenMode, setScreenMode] = useState<ScreenMode>(ScreenMode.METER);
	const [currentCustomer, setCurrentCustomer] = useState<CustomerDetails>(new CustomerDetails());
	const [districts, setDistricts] = useState<any[]>([]);
	const [customerMeters, setCustomerMeters] = useState<CustomerMeter[]>([]);

	const { getColumnSearchProps } = useColumnFilter<CustomerDetails>()

	const { getColumnSearchProps: getMeterColumnSearchProps } = useColumnFilter<MeterDetails>()

	const { state: { currentSite } } = useStore();

	const fetchData = useCallback(async () => {
		if (currentSite.id) {
			try {
				setLoading(true);
				const siteId = currentSite.id;
				let result: FetchResponse = await CustomerService.getCustomerPerSite(siteId);
				if (result?.status === "success") {
					setCustomerDetails(result.data);
					setCustomerDetailsTMP(result.data)
				}
				// get meter list
				let res: FetchResponse = await MeterService.getMeterPerSite(siteId);
				if (res?.status === "success") {
					setMeterDetails(res.data);
					setMeterDetailsTMP(res.data)
				}
				const district: any[] = await ProspectService.getDistrictBySiteId(siteId);
				setDistricts(district);
				setScreenMode(ScreenMode.METER);
			} catch (error) {
				console.log(error);
			}
			finally {
				setLoading(false);
			}
		}
	}, [currentSite.id]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleChangePageSize = (current: number, newPageSize: number) => {
		setPageSize(newPageSize);
	};

	// Pagination configuration
	const pagination = {
		pageSize,
		showSizeChanger: true,
		onShowSizeChange: handleChangePageSize,
		// Other pagination configurations
	};

	const detailsColumns: TableColumnsType<CustomerDetails> = [
		{
			title: 'Date de création',
			dataIndex: 'createdDate',
			filterSearch: true,
			defaultSortOrder: "descend",
			hidden: true,
			sorter: (a, b) => {
				if (new Date(a.createdDate)?.getTime() === new Date(b.createdDate)?.getTime()) {
					return 0;
				} else if (new Date(a.createdDate)?.getTime() > new Date(b.createdDate)?.getTime()) {
					return 1;
				} else {
					return -1;
				}
			},
		},
		{
			title: 'Nom',
			dataIndex: 'lastName',
			filterSearch: true,
			sorter: (a, b) => {
				return a.lastName.localeCompare(b.lastName);
			},
			...getColumnSearchProps('lastName')
		},
		{
			title: 'Prenom',
			dataIndex: 'firstName',
			filterSearch: true,
			sorter: (a, b) => a.firstName.localeCompare(b.firstName),
			...getColumnSearchProps('firstName')
		},
		{
			title: 'Adresse',
			dataIndex: 'address',
			filterSearch: true,
			sorter: (a, b) => a.address.localeCompare(b.address),
			...getColumnSearchProps('address', 'select', customerDetails)

		},
		{
			title: 'Quartier',
			dataIndex: 'district',
			filterSearch: true,
			sorter: (a, b) => a.district.localeCompare(b.district),
			...getColumnSearchProps('district', 'select', customerDetails),
		},
		{
			title: 'Type de branchement',
			dataIndex: 'branchType',
			filterSearch: true,
			align: 'center',
			sorter: (a, b) => a.site.localeCompare(b.site),
			render: (text: string) => text ? text.toUpperCase() : "",
			...getColumnSearchProps('branchType', 'select', customerDetails),
		},
		{
			title: 'Compteur',
			dataIndex: 'meter',
			filterSearch: true,
			sorter: (a, b) => a.meter.localeCompare(b.meter),
			...getColumnSearchProps('meter'),
		},
	];

	const meterColumns: TableColumnsType<MeterDetails> = [
		{
			title: 'Installation',
			dataIndex: 'installationDate',
			filterSearch: true,
			defaultSortOrder: "descend",
			sorter: (a, b) => {
				if (new Date(a.installationDate)?.getTime() === new Date(b.installationDate)?.getTime()) {
					return 0;
				} else if (new Date(a.installationDate)?.getTime() > new Date(b.installationDate)?.getTime()) {
					return 1;
				} else {
					return -1;
				}
			},
			...getMeterColumnSearchProps('installationDate', 'date'),
			render: (text) => {
				if (text === null || text === undefined) return "";
				return moment(text).format(dateFormat)
			},
		},
		{
			title: 'Numero Compteur',
			dataIndex: 'meterNumber',
			filterSearch: true,
			sorter: (a, b) => {
				return a.lastName.localeCompare(b.lastName);
			},
			...getMeterColumnSearchProps('meterNumber')
		},
		{
			title: 'Client',
			dataIndex: 'lastName',
			sorter: (a, b) => a.lastName.localeCompare(b.lastName),
			...getMeterColumnSearchProps('lastName', "search", [], undefined, (_text, record) => getCustomerFullName(record), getFullName)
		},
		{
			title: 'Adresse',
			dataIndex: 'address',
			filterSearch: true,
			sorter: (a, b) => a.address.localeCompare(b.address),
			...getMeterColumnSearchProps('address', 'select', meterDetails)

		},
		{
			title: 'Quartier',
			dataIndex: 'district',
			filterSearch: true,
			sorter: (a, b) => a.district.localeCompare(b.district),
			...getMeterColumnSearchProps('district', 'select', meterDetails),
		},
	];

	const { confirm } = Modal;

	const props: UploadProps = {
		fileList: currentFile,
		onRemove: (file) => {
			setCurrentFile([]);
		},
		beforeUpload: async (file) => {
			const types: string[] = ["text/tab-separated-values", "text/csv"];
			const isValid = types.includes(file.type);
			if (!isValid) {
				message.error(`Le fichier doit être .csv`);
			} else {
				setCurrentFile([file]);
				const formData = new FormData();
				formData.append('customers', file as FileType);
				try {
					const res = await CustomerService.importCustomer(formData);
					if (res.status === 'error') {
						if (res.message) {
							message.error(res.message);
						} else {
							confirm({
								title: `Erreurs`,
								icon: <ExclamationCircleFilled />,
								content: (
									<div>
										{res.data.map((item: any, index: number) => (
											<div key={index}>{item}</div>
										))}
									</div>
								),
								okText: `Terminer`,
								onOk: () => {
								},
							});
						}
					} else {
						message.success(res.message);
						fetchData();
						// dispatch({ payload: true, type: 'REFRESH_SITE' })
					}
					// console.log("response", res);
				} catch (e) {
					console.log("error", e);
				}
			}
			return isValid || Upload.LIST_IGNORE;
		},
	};

	const search = (e: any) => {
		const value = e.target.value;
		const regExp = new RegExp(value, 'gi');
		const filterTable = customerDetailsTMP.filter((o: any) => Object.keys(o).some(k => regExp.test(o[k])));

		setCustomerDetails(filterTable)
	};

	const searchMeter = (e: any) => {
		const value = e.target.value;
		const regExp = new RegExp(value, 'gi');
		const filterTable = meterDetailsTMP.filter((o: any) => Object.keys(o).some(k => regExp.test(o[k])));

		setMeterDetails(filterTable)
	};

	const onScreenModeClicked = () => {
		if (screenMode === ScreenMode.METER) {
			setScreenMode(ScreenMode.CUSTOMER);
		} else {
			setScreenMode(ScreenMode.METER);
		}
	}

	const getCustomerMeter = useCallback(async (customerId: number, siteId: number) => {
		const res = await CustomerMeterService.GetCustomerMeter(customerId, siteId);
		if (res.status === "success") {
			let customerMeters: CustomerMeter[] = res.data;
			if (customerMeters.length > 0) {
				customerMeters = customerMeters.map((item: any) => {
					if (item.installationDate != null) {
						item.installationDate = dayjs(item.installationDate);
					}
					return item;
				});
				setCustomerMeters(customerMeters);
			}
		}
	}, []);

	const onCustomerClicked = (id: number) => {
		const fetch = async () => {
			try {
				setLoading(true);
				let res = await CustomerService.getCustomerById(id, currentSite.id);
				if (res.status === "success") {
					const customer = res.data[0];
					if (customer.birth != null) {
						customer.birth = dayjs(customer.birth);
					}
					setCurrentCustomer(customer);
					await getCustomerMeter(id, currentSite.id);
					setScreenMode(ScreenMode.FICHE);
				}
			} finally {
				setLoading(false);
			}
		}
		fetch();
	}

	const onSubmitCustomer = () => {
		setLoading(true);
		customerForm.validateFields()
			.then(async () => {
				try {
					const values = { ...customerForm.getFieldsValue(), id: currentCustomer?.id };
					const res: FetchResponse = await CustomerService.update(values, currentSite.id);
					if (res.status == "success") {
						notification.success({ message: res.message, });
						fetchData();
					} else {
						notification.error({ message: res.message, });
					}
				} catch (error: any) {
					notification.error({ message: error?.message || "Erreur inconnue!", });
				}
			})
			.catch((e) => {
				console.log("Error ", e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onFinish = (data: any) => { };

	const onFinishFailed = (data: any) => {
	};

	const onFieldsChange = (changed: any, values: any) => {
	};

	const getCustomerFullName = (record: MeterDetails) => {
		return (
			<a onClick={() => onCustomerClicked(record.idUser)}>
				{`${record.lastName || ""} ${record.firstName || ""}`}
			</a>
		)
	};

	useEffect(() => {
		if (currentCustomer) {
			customerForm.setFieldsValue(currentCustomer);
		}
	}, [currentCustomer, customerForm]);

	return (
		<Space direction="vertical" size={"large"} style={{ display: 'flex' }}>
			{screenMode == ScreenMode.CUSTOMER && <InnerCard
				title={"Client"}
				icon={<CreditCardOutlined />}
				extra={
					<div className="customer-db" onClick={onScreenModeClicked}>
						<DashboardOutlined />
					</div>
				}
			>
				<Row justify={"space-between"} align={"middle"} style={{ marginBottom: 10 }}>
					<Col>
						<Input.Search
							placeholder="Rechercher..."
							enterButton
							onChange={search}
						/>
					</Col>
					<Col style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: "20px" }}>Total: <strong>{customerDetails.length}</strong></div>
						<Upload {...props}>
							<Button icon={<UploadOutlined />}>Importer un fichier</Button>
						</Upload>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={24}>
						<Table
							columns={detailsColumns}
							rowKey={"id"}
							dataSource={customerDetails}
							size="small"
							loading={loading}
							pagination={pagination}
							scroll={{ y: 585 }}
						/>
					</Col>
				</Row>
			</InnerCard>}
			{screenMode == ScreenMode.METER && <InnerCard
				title={"Compteur"}
				icon={<DashboardOutlined />}
				extra={
					<div className="customer-db" onClick={onScreenModeClicked}>
						<CreditCardOutlined />
					</div>
				}
			>
				<Row justify={"space-between"} align={"middle"} style={{ marginBottom: 10 }}>
					<Col>
						<Input.Search
							placeholder="Rechercher..."
							enterButton
							onChange={searchMeter}
						/>
					</Col>
					<Col style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: "20px" }}>Total: <strong>{meterDetails.length}</strong></div>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={24}>
						<Table
							columns={meterColumns}
							rowKey={"meterId"}
							dataSource={meterDetails}
							size="small"
							loading={loading}
							pagination={pagination}
							scroll={{ y: 585 }}
						/>
					</Col>
				</Row>
			</InnerCard>}
			{screenMode == ScreenMode.FICHE &&
				<>
					<InnerCard
						title={`Fiche client: ${getFullName(currentCustomer)}`}
						icon={<UserOutlined />}
						extra={
							<div className="customer-db" onClick={onScreenModeClicked}>
								<RollbackOutlined />
							</div>
						}
					>
						<Spin size="large" spinning={loading}>
							<Form
								{...formItemLayout}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								onValuesChange={onFieldsChange}
								autoComplete="off"
								form={customerForm}
								labelAlign="left"
								initialValues={{
									...currentCustomer
								}}
							>
								<Input disabled name="id" id="id" type="hidden" />
								<Row justify="center">
									<Col span={12}>
										<Form.Item
											{...rowItemLayout}
											label="Nom"
											name="lastName"
											rules={[{ required: true, message: "Nom requis" }]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={11} offset={1}>
										<Form.Item
											{...rowItemLayout}
											label="Prenom"
											name="firstName"
											rules={[{ required: false }]}
										>
											<Input name="firstName" id="firstName" />
										</Form.Item>
									</Col>
								</Row>
								<Form.Item
									label="Appelation"
									name="nickName"
									rules={[
										{ required: false },
									]}
								>
									<Input name="nickname" id="nickname" />
								</Form.Item>
								<Row justify="center">
									<Col span={12}>
										<Form.Item
											label="Date de naissance"
											name="birth"
											{...rowItemLayout}
											rules={[
												{ required: false },
												() => ({
													validator(_, value) {
														if (dayjs(value).isAfter(dayjs())) {
															return Promise.reject("La date doit être inférieure à la date actuelle");
														}
														return Promise.resolve();
													},
												}),
											]}
										>
											<DatePicker
												format="DD/MM/YYYY"
												style={{ width: '100%' }}
												maxDate={dayjs()}
											/>
										</Form.Item>
									</Col>
									<Col span={11} offset={1}>
										<Form.Item
											{...rowItemLayout}
											label="Sexe"
											name={"gender"}
											rules={[
												{ required: true, message: "Sexe requis" }
											]}
										>
											<Radio.Group name="gender" id="gender">
												<Radio value="m">Masculin</Radio>
												<Radio value="f">Féminin</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
								</Row>
								<Row justify="center">
									<Col span={12}>
										<Form.Item
											{...rowItemLayout}
											label="Adresse"
											name="address"
											rules={[{ required: true, message: "Adresse requis" }]}
										>
											<Input name="address" id="address" />
										</Form.Item>
									</Col>
									<Col span={11} offset={1}>
										<Form.Item
											{...rowItemLayout}
											label="Quartier"
											name="districtId"
											rules={[{ required: true, message: "Quartier requis" }]}
										>
											<Select
												showSearch
												style={{ width: "100%" }}
												fieldNames={{
													label: "label",
													value: "id",
												}}
												options={districts}
												optionFilterProp="label"
											/>
											{/* <Input name="district" id="district" /> */}
										</Form.Item>
									</Col>
								</Row>
								<Form.Item wrapperCol={{ offset: 19 }}>
									<Flex gap="small">
										<Button htmlType="submit" onClick={onScreenModeClicked} block>Annuler</Button>
										<Button type="primary" htmlType="submit" onClick={onSubmitCustomer} className="bg-blue" block>Sauvegarder</Button>
									</Flex>
								</Form.Item>
							</Form>
						</Spin>
					</InnerCard >
					<CustomerMeterComponent
						originData={customerMeters}
						loading={loading}
						customerId={currentCustomer.id}
						refreshData={async () => {
							await getCustomerMeter(currentCustomer.id, currentSite.id);
							await fetchData();
						}}
					/>
				</>
			}
		</Space >
	)
};

export default CustomerPage;