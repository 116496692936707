import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Select,
  InputNumber,
  DatePicker,
  notification,
  Row,
  Col,
  Button,
  Image
} from "antd";
import { ReportService } from "../../../services";
import { numberAsCurrency } from "../../../utils/string";

interface ReportFormProps {
  open: boolean;
  onOk: (params?: any) => void;
  onCancel: () => void;
  data: ReportFormPayload | null;
}

export interface ReportFormPayload {
  id: number;
  reportDate: any;
  customers: { value: number; label: string }[];
  meters: { value: number; label: string }[];
  districts: { value: number; label: string }[];
  reportId: number;
  siteId: number;
  currentIndex: number;
  lastBilledIndex: number;
  customerId: number;
  customerMeterId: number;
  districtId: number;
  volumeConsumed: number;
  variables: { value: number; label: string }[];
  totalToPay: number;
  amountConsumed: number;
  image: string;
  invoiceId: number;
}

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const ReportForm: React.FC<ReportFormProps> = ({
  open,
  onOk,
  onCancel,
  data = null,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [_data, setData] = useState<any>(null);

  useEffect(() => {
    form.resetFields();
    return () => {};
  }, [form]);

  useEffect(() => {
    setData(data);
    return () => {};
  }, [data]);

  const onFinish = (data: any) => {
    form.resetFields();
  };

  const onFinishFailed = (data: any) => {
    console.log("Failed ", data);
  };

  const onSubmit = (generateInvoice: boolean = false) => {
    setLoading(true);
    form
      .validateFields()
      .then(async () => {
        try {
          const values = {
            ...form.getFieldsValue(),
            id: data?.id,
            siteId: data?.siteId,
            generateInvoice,
          };
          const response = await ReportService.updateReport(values);
          onOk(response);
          notification.success({ message: generateInvoice ? "Relevé sauvegardé et facture generée" : "Relevé sauvegardé" });
        } catch (error: any) {
          notification.error({ message: error?.message || "Erreur inconnue!" });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onFieldsChange = (changed: any, values: any) => {
    if ("currentIndex" in changed) {
      ReportService.getReportById(_data.reportId, _data?.siteId, {
        currentIndex: changed.currentIndex,
      })
        .then((response) => {
          setData((prev: any) => ({ ...prev, ...response }));
          form.setFieldsValue({
            ...values,
            volumeConsumed: response.volumeConsumed,
            ...response.variables.reduce((prev: any, curr: any) => {
              prev[curr.label] = curr.value;
              return prev;
            }, {}),
            totalToPay: response.totalToPay,
            amountConsumed: response.amountConsumed,
          });
        })
        .catch((error) => {
          notification.error({ message: error?.message || "Erreur inconnue!" });
        });
    }
  };

  if (_data === null) return null;

  return (
    <Modal
      title="Modification relevée"
      centered
      open={open}
      styles={{ header: { textAlign: "center" } }}
      onCancel={onCancel}
      width={window.innerWidth * 0.7}
      okText={data?.id ? "MODIFIER" : "SAUVEGARDER"}
      cancelText={"ANNULER"}
      confirmLoading={loading}
      footer={[
        <Button key="1" disabled={loading} type="default" onClick={onCancel}>
          ANNULER
        </Button>,
        !data?.invoiceId ? (
          <Button key="2" disabled={loading} type="primary" onClick={() => onSubmit()}>
            SAUVEGARDER
          </Button>
        ) : null,
        !data?.invoiceId ? (
          <Button disabled={loading} loading={loading} key="3" type="primary" onClick={() => onSubmit(true)}>
            SAUVEGARDER ET GENERER FACTURE
          </Button>
        ) : null,
      ]}
    >
      <Form
        {...formItemLayout}
        initialValues={data || {}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        disabled={data?.invoiceId != null}
        onValuesChange={onFieldsChange}
      >
        <Form.Item label={<b>Relevé</b>}>
          <br />
          <Row>
            <Col span={12}>
              <Form.Item
                label="Date"
                name="reportDate"
                rules={[{ required: true, message: "Date requis" }]}
              >
                <DatePicker format="DD/MM/YYYY" placeholder="Date ..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Client"
                name="customerId"
                rules={[{ required: true, message: "Client requis" }]}
              >
                <Select options={_data.customers} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Dernier index"
                name="currentIndex"
                rules={[{ required: true, message: "Dernier index requis" }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Compteur"
                name="customerMeterId"
                rules={[{ required: true, message: "Compteur requis" }]}
              >
                <Select options={data?.meters} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Image src={_data.image} alt="" height={150}/>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Quartier"
                name="districtId"
                rules={[{ required: true, message: "Quartier requis" }]}
              >
                <Select options={_data.districts} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label={<b>Facture</b>}>
          <br />
          <Row>
            <Col span={12}>
              <Col span={24}>
                <Form.Item
                  label="Dernier index relevé"
                  name="currentIndex"
                  rules={[{ required: true, message: "Dernier index requis" }]}
                >
                  <InputNumber disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Dernier index facturé"
                  name="lastBilledIndex"
                  rules={[{ required: true, message: "Dernier index requis" }]}
                >
                  <InputNumber disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Volume consommé"
                  style={{
                    color: _data.volumeConsumed > 0 ? "#10a41d" : "red",
                  }}
                  name="volumeConsumed"
                  rules={[
                    { required: true, message: "Dernier index requis" },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }

                        if (value < 0) {
                          return Promise.reject("Volume consommé négatif");
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    disabled
                    style={{
                      color: _data.volumeConsumed > 0 ? "#10a41d" : "red",
                      borderColor: _data.volumeConsumed > 0 ? "#10a41d" : "red",
                    }}
                  />
                </Form.Item>
              </Col>
            </Col>

            <Col span={12}>
              <Col span={24}>
                <Form.Item label="Montant consommé" name="amountConsumed" >
                  <InputNumber disabled addonAfter="Ar" formatter={numberAsCurrency} />
                </Form.Item>
              </Col>

              {(_data.variables || []).map((variable: any) => (
                <Col span={24} key={variable.id + variable.label}>
                  <Form.Item
                    label={variable.label}
                    name={variable.label}
                    initialValue={variable.value}
                  >
                    <InputNumber disabled addonAfter="Ar" formatter={numberAsCurrency}/>
                  </Form.Item>
                </Col>
              ))}

              <Col span={24}>
                <Form.Item
                  className="report-form-totalToPay"
                  label="Total à payer"
                  style={{ color: "#10a41d", border: "0px" }}
                  name="totalToPay"
                >
                  <InputNumber
                    disabled
                    style={{
                      color: "#10a41d",
                      border: "0px",
                      borderColor: "#10a41d",
                    }}
                    formatter={numberAsCurrency}
                    addonAfter="Ar"
                  />
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
