import React, { useEffect, useState } from "react";
import { SettingService } from "../../../services";
import { useStore } from "../../../store";
import { Button, Form, Input, InputNumber, Space, Upload, notification } from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Site } from "../../../models";
import { generateFileURL } from "../../../utils";


export default function MailSetting() {
  const {
    state: { user },
	  dispatch
  } = useStore();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState<any[]>([]);

  useEffect(() => {
    form.resetFields();
    return () => {};
  }, [form]);

  useEffect(() => {
    if (user?.site) {
      loadMailSettings();
    }
    return () => {};
  }, [user?.site]);


  const loadMailSettings = async () => {
    const _site = user?.site;
    if (_site) {
      try {
        const { emails, site } = await SettingService.getSettings(_site?.id);

        form.setFieldsValue({
          emails: emails.length > 0 ? emails.map((value) => ({ value })) : [{ value: null }],
          menage: site.menage,
        });

        setFileList(site);
      } catch (error) {}
    }
  };

  const setFileList = (site:Site) =>{
    if(site.invoiceTemplate){
      const url = site.invoiceTemplate
      setDefaultFileList(() => [{name:'Model facture', uid:Date.now(), url:generateFileURL(url)}]);
    }
  }

  const onFinish = (data: any) => {
    setLoading(true);
    form
      .validateFields()
      .then(async () => {
        try {
          const value = { ...form.getFieldsValue(), siteId: user?.site?.id };

          const data = new FormData();

          data.append('siteId', value.siteId);
          data.append('menage', value.menage);
          data.append('emails', JSON.stringify(value.emails));
          data.append('template', value.template);
         
          const {site} = await SettingService.settings(data);

          setFileList(site);

          dispatch({ payload: { ...user, site }, type: "SET_USER",});

          notification.success({ message: "Paramètres sauvegardés" });
        } catch (error: any) {
          notification.error({ message: error?.message || "Erreur inconnue!" });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const checkUniqueEmail = (_: any, value: any) => {
    const values: any[] = form.getFieldValue("emails");

    if (values.length && values.filter((v) => v.value === value).length > 1) {
      return Promise.reject(new Error("Email déjà renseigné"));
    }

    return Promise.resolve();
  };

  const onSelectInvoiceTemplate = (e: any,...rest:any) => {
    const file = e.file;
    if (!file?.status) {
      setDefaultFileList(()=>[{name:'Model facture', uid: 0}])
      form.setFieldsValue({
        ...form.getFieldsValue(),
        template: file,
      });
    }else{
      setDefaultFileList(()=>[])
      form.setFieldsValue({
        ...form.getFieldsValue(),
        template: undefined,
      });

    }
  };

  const validateFiles = (_:any,value:any)=>{
    if(!value || value?.fileList?.length === 0 || defaultFileList.length === 0){
      return Promise.reject(new Error("Fichier requis"));
    }

    return Promise.resolve();
  }

  return (
    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        style={{ width: 600, }}
        layout="vertical"
      >
			  <h3>Nombre de menages</h3>
			  <Form.Item
				  name={"menage"}
			  >
				  <InputNumber min={0} changeOnWheel style={{ width: '100%' }} />
			  </Form.Item>
			  <h3>Mail</h3>
        <Form.List rules={[]} name="emails">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <Space.Compact
                    block
                    key={key + "-mails"}
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name={[name, "value"]}
                      style={{ width: "100%" }}
					  
                      rules={[
                        {
                          required: true,
                          message: "Champ requis",
                        },
                        {
                          type: "email",
                          message: "Invalide email",
                        },
                        {
                          validator: checkUniqueEmail,
                        },
                      ]}
                      {...restField}
                    >
                      <Input />
                    </Form.Item>

                    {index > 0 ? (
                      <Form.Item style={{ marginLeft: 20 }}>
                        <MinusCircleOutlined
                          style={{ color: "red" }}
                          onClick={() => remove(name)}
                        />
                      </Form.Item>
                    ) : null}

                    {fields.length - 1 === index ? (
                      <Form.Item style={{ marginLeft: 20 }}>
                        <PlusCircleOutlined onClick={() => add()} />
                      </Form.Item>
                    ) : null}
                  </Space.Compact>
                );
              })}
            </>
          )}
        </Form.List>

        <Form.Item
          name="template"
          rules={[
            {
              validator: validateFiles,
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={onSelectInvoiceTemplate}
            multiple={false}
            maxCount={1}
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            fileList={defaultFileList}
            onDownload={()=> false}
          >
            <Button icon={<UploadOutlined />}>
              Sélectionner le model de facture
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            className="bg-blue"
            type="primary"
            htmlType="submit"
            disabled={loading}
            block
            loading={loading}
          >
            Sauvegarder
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
