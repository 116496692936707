import { BilibiliOutlined, DashboardOutlined, DollarOutlined, DownOutlined, FundOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, NodeIndexOutlined, ScheduleOutlined, SettingOutlined, UsergroupAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps, Space, Spin, Form, Select, Row, Flex, FormProps, message, Button, Dropdown, Tooltip } from 'antd';
import React, { CSSProperties, FC, ReactNode, Suspense, useEffect, useState } from "react";
import { useStore } from '../../store';
import { Link, useLocation } from 'react-router-dom';
import { FetchResponse, Site } from '../../models';
import { SiteService } from '../../services';
import packageJson from '../../../package.json'

const { Header, Content, Footer, Sider } = Layout;

interface LayoutProps {
	children: ReactNode;
}

const RootPage: FC<LayoutProps> = ({ children }) => {
	const { state: { user, currentSite, refreshSite }, dispatch } = useStore()
	const l = useLocation()
	const [activeKeys, setActiveKeys] = useState([l.pathname])
	const [sites, setSites] = useState<Site[]>([]);
	const [messageApi, contextHolder] = message.useMessage();
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			let result: FetchResponse = await SiteService.getUserSites();
			if (result?.status === "success") {
				const sites: Site[] = result.data;
				if (sites?.length > 0) {
					setSites(sites);
				} else {
					setSites([]);
				}
			}
			console.log("refresh site");
		};
		fetch();
	}, [refreshSite, user?.site]);

	const onClick: MenuProps['onClick'] = (e) => {
		if (e.key === 'logout') {
			dispatch({ payload: true, type: 'LOGOUT' })
		} else {
			setActiveKeys([e.key])
		}
	};

	const items = () => {
		if (user?.type === 'admin') {
			return [
				getItem(<Link className='txt-white' to={'/'}>Tableau de bord</Link>, '/', <DashboardOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/tarifs'}>Tarif</Link>, '/tarifs', <BilibiliOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/parametres'}>Configuration</Link>, '/parametres', <SettingOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/user-manager'}>Utilisateurs</Link>, '/user-manager', <UsergroupAddOutlined className='txt-white' />),
			]
		} else if (user?.type === 'manager') {
			return [
				getItem(<Link className='txt-white' to={'/'}>Tableau de bord</Link>, '/', <DashboardOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/report'}>Relevé</Link>, '/report', <ScheduleOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/customer'}>Client</Link>, '/customer', <UserOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/prospects'}>Prospects</Link>, '/prospects', <UserSwitchOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/invoices'}>Caisse</Link>, '/invoices', <DollarOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/volume-produit-distribue'}>Volume</Link>, '/volume-produit-distribue', <FundOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'#'}>Paramétrage</Link>, '/parametrage', <SettingOutlined className='txt-white' />,
					[
						getItem(<Link className='txt-white' to={'/parametrage/index-initiaux'}>Index initiaux</Link>, '/parametrage/index-initiaux', <NodeIndexOutlined className='txt-white' />)
					],
				),
			]
		} else if (user?.type === 'cashier') {
			return [
				getItem(<Link className='txt-white' to={'/invoices'}>Factures</Link>, '/invoices', <ScheduleOutlined className='txt-white' />),
			]
		}
	}

	const onValuesChange: FormProps<FormType>['onValuesChange'] = async (data) => {
		try {
			const result: any = await SiteService.setDefaultSite({ siteId: data.site });
			if (result.status === "success") {
				const currentSite: Site = sites.filter((item) => item.id === data.site)[0];
				dispatch({ payload: currentSite, type: 'SET_CURRENTSITE' });
			} else {
				messageApi.open({
					type: 'error',
					content: 'Erreur lors du changement du site',
				});
			}
		} catch (e) {
			console.info("saving site error: ", e);
			messageApi.open({
				type: 'error',
				content: 'Erreur lors du changement du site',
			});
		}
	}

	const handleMenuClick: MenuProps['onClick'] = (e) => {
		if (e.key == '2') {
			dispatch({ payload: true, type: 'LOGOUT' });
		}
	};

	const rightItems: MenuProps['items'] = [
		{
			label: <Link to={'/profile'}>Profil</Link>,
			key: '1',
			icon: <UserOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: 'Déconnexion',
			key: '2',
			icon: <LogoutOutlined />,
		},
	];

	const menuProps = {
		items: rightItems,
		onClick: handleMenuClick,
		style: {
			width: '210px',
		} as CSSProperties
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			{contextHolder}
			<Sider
				breakpoint="lg"
				collapsible
				collapsed={collapsed}
				collapsedWidth={60}
				onBreakpoint={(broken) => {
				}}
				onCollapse={(collapsed, type) => {
					setCollapsed(collapsed);
				}}
				className='blue-gradient-background txt-white sider-shadow'
			>
				<Space direction='horizontal' size="small">
					<div className="awater-logo">
						<img
							alt='logo'
							style={{ borderRadius: '50%' }}
							src={`/assets/images/logo.png`}
						/>
					</div>
					{collapsed == false && <div>
						<span style={{ fontWeight: 'bold', color: 'white' }}>A-Water</span>
					</div>}
				</Space>
				<Menu defaultSelectedKeys={activeKeys} style={{ backgroundColor: 'transparent' }} onClick={onClick} mode="inline" items={items()} />
			</Sider>
			<Layout>
				<Header style={{ padding: 0, height: 60 }} className='bg-blue txt-white'>
					<Flex style={{ height: '100%' }} justify='space-between' align='center'>
						<Flex style={{ height: '100%' }} justify='center' align='center' flex={1}>
							<Row justify={"center"}>
								{currentSite.id && <Form
									name="global_site"
									layout="inline"
									onValuesChange={onValuesChange}
								>
									<Form.Item
										label=""
										name={"site"}
										rules={[{ required: true, message: 'Veuillez choisir un site' }]}
										initialValue={currentSite.id}
									>
										<Select
											options={sites}
											fieldNames={{
												label: "label",
												value: "id",
											}}
											key={"id"}
											style={{ width: 200 }}
											placeholder="Tous"
											optionFilterProp="children"
											filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
											}
										/>
									</Form.Item>
								</Form>}
							</Row>
						</Flex>
						<Space>
							<Dropdown menu={menuProps} trigger={["click"]} className='user-dropdown'>
								<Space>
									<span></span>
									<div className="awater-logo" style={{ margin: '0px', width: '30px', height: '30px' }}>
										<img
											alt='profil'
											style={{
												borderRadius: '50%',
											}}
											src={`/assets/images/profil.png`}
										/>
									</div>
									<span style={{ fontWeight: 'bold' }}>{user?.login}</span>
									<DownOutlined style={{ marginRight: '15px' }} />
								</Space>
							</Dropdown>
						</Space>
					</Flex>
				</Header>
				<Content>
					<div
						style={{
							padding: 25,
							paddingBottom: 0,
							minHeight: 360,
						}}
					>
						<Suspense fallback={<div style={{ position: 'absolute', top: '50%', left: '50%' }}><Spin size="large" /></div>}>
							{children}
						</Suspense>
					</div>
				</Content>
				<Footer style={{ textAlign: 'center' }}>
					Copyright ©{new Date().getFullYear()}. Version {packageJson.version}
				</Footer>
			</Layout>
		</Layout>
	)
};

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group',
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
	} as MenuItem;
}

class FormType {
	site: number = 0;
}

export default RootPage;